/* Copyright 2013 - 2024 Waiterio LLC */
import { css } from '@emotion/react'
import androidToaster from './androidToaster.js'

const defaults = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body,
  html {
    height: 100%;
    width: 100%;
    margin: 0;
    outline: 0;
    background-color: var(--color-background, #dce1e3);
    color: var(--color-base);
    fill: var(--color-base);
  }

  body,
  html,
  button,
  input,
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  #root:not(:empty) {
    width: 100%;
    height: 100%;
  }

  [data-reactroot] {
    height: 100% !important;
  }

  iframe {
    margin: 0;
    border: 0;
  }

  body,
  button,
  input,
  textarea {
    font-size: 18px;
  }

  input {
    padding: 0;
    margin: 0;
  }

  i {
    font-size: 22px;
  }

  a {
    color: inherit;
    text-decoration: inherit;
  }

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    border-collapse: collapse;
    vertical-align: baseline;
    background: transparent;
  }

  table,
  tbody,
  tr {
    width: 100%;
  }

  input,
  textarea {
    color: inherit;
    background-color: inherit;
    -webkit-appearance: none;
    min-width: 0;
    border-width: 0;
    outline: none;
    box-sizing: border-box;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='checkbox'] {
    -webkit-appearance: checkbox;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  textarea: {
    background-color: transparent;
    resize: none;
    border: 0;
    outline: 0;
    padding: 16px;
    margin: 0;
  }

  form {
    margin: 0;
  }

  button: {
    background-color: inherit;
  }

  ::-webkit-input-placeholder {
    color: #ababab;
  }

  :-moz-placeholder {
    color: #ababab;
  }

  ::-moz-placeholder {
    color: #ababab;
  }

  :-ms-input-placeholder {
    color: #ababab;
  }

  video::-internal-media-controls-download-button {
    display: none;
  }

  video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }

  video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }

  video::-webkit-media-controls-play-button,
  video::-webkit-media-controls-start-playback-button {
    opacity: 0;
    pointer-events: none;
    width: 5px;
  }

  @media (max-width: 768px) {
    body,
    button,
    input,
    textarea {
      font-size: 20px;
    }
  }

  div.public-DraftEditor-content {
    padding: 8px 12px;
    font-size: 22px;
    line-height: 150%;
  }
  div.public-DraftEditor-content h1,
  div.public-DraftEditor-content h2,
  div.public-DraftEditor-content h3,
  div.public-DraftEditor-content h4,
  div.public-DraftEditor-content h5,
  div.public-DraftEditor-content h6 {
    margin: 16px 0px;
  }
  #contentEditorWrapper div.public-DraftEditor-content {
    min-height: 256px;
  }

  ${androidToaster}
`

export default defaults
