/* Copyright 2013 - 2024 Waiterio LLC */
/* eslint react/no-unescaped-entities: ["error", {"forbid": ['>', '"', '}'] }] */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import useArticles from '@polyblog/react/useArticles.js'
import colLg4 from '@stiloso/styles/bootstrap/colLg4.js'
import colLg6 from '@stiloso/styles/bootstrap/colLg6.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'

const formatDate = ({ date, locale }) =>
  new Date(date).toLocaleString(locale, { dateStyle: 'long' })

const blogSection = css`
  padding: 80px 0px;
  background-color: white;

  @media (max-width: 992px) {
    padding: 64px 0px;
  }

  @media (max-width: 768px) {
    padding: 48px 0px;
  }
`

const sectionTitle = css`
  font-size: 36px;
  text-transform: capitalize;
  font-weight: bold;
  color: var(--color-primary);
  text-align: center;

  @media (max-width: 992px) {
    font-size: 28px;
    line-height: 32px;
  }
`

const articleBox = css`
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  display: block;
  transition: all 0.25s ease-in-out;

  @media (hover: hover) {
    :hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      transform: translateY(-10px);
      fill: var(--color-primary);
      h5 {
        color: var(--color-secondary-light);
      }
    }
  }

  :focus-visible {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transform: translateY(-10px);
    fill: var(--color-primary);
    h5 {
      color: var(--color-secondary-light);
    }
  }

  &:not(.disabled):active {
    h5 {
      color: var(--color-secondary-dark);
    }
  }
`

const articleDetails = css`
  padding: 20px 15px 15px;
  font-size: 14px;
  font-weight: 400;
`

const articleTitle = css`
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
  color: #302e99;
`

const articleAuthor = css`
  font-size: 14px;
  color: var(--color-base);
  margin-bottom: 10px;
  font-weight: 500;
`

const articleContent = css`
  margin-bottom: 16px;
  margin-top: 0;
`

const BlogSection = ({ blogId, locale }) => {
  const { t } = useTranslation(locale)

  let { data: articles } = useArticles({
    blogId,
    locale,
    limit: 3,
    published: true,
    sortField: 'lastRewriteTime',
    sortDirection: 'DESC',
    fields: [
      'author',
      'coverUrl',
      'creationTime',
      'description',
      'lastRewriteTime',
      'locale',
      'slug',
      'title',
      'url',
    ],
  })

  if (articles) {
    articles = Object.values(articles).map(article => {
      let postedBy = t('{{author}} - {{date}}')
      postedBy = postedBy
        .replace('{{author}}', article.author || 'Joe Dodds')
        .replace(
          '{{date}}',
          formatDate({
            date: article.lastRewriteTime || article.creationTime,
            locale: article.locale,
          }),
        )

      return {
        ...article,
        url: `/blog/${article.locale}/${article.slug}/`,
        postedBy,
      }
    })
  }

  return (
    articles && (
      <section id="blog-section" css={blogSection}>
        <div css={container}>
          <div css={[row, { justifyContent: 'center' }]}>
            <div css={[colMd6, colLg6]}>
              <h2 css={sectionTitle}>{t('Articles')}</h2>
            </div>
          </div>
          <div css={row}>
            {articles?.map(
              ({ description, slug, coverUrl, title, postedBy, url }) => (
                <div key={slug} css={[colSm12, colLg4, { marginTop: 48 }]}>
                  <a css={articleBox} href={url}>
                    <picture>
                      <source
                        type="image/webp"
                        media="(-webkit-min-device-pixel-ratio: 2)"
                        srcSet={coverUrl?.replace('.jpg', '-768w.webp')}
                      />
                      <source
                        type="image/webp"
                        srcSet={coverUrl?.replace('.jpg', '-640w.webp')}
                      />
                      <source
                        type="image/jpeg"
                        media="(-webkit-min-device-pixel-ratio: 2)"
                        srcSet={coverUrl?.replace('.jpg', '-768w.jpg')}
                      />
                      <source
                        type="image/jpeg"
                        srcSet={coverUrl?.replace('.jpg', '-640w.jpg')}
                      />
                      <img
                        css={{ width: '100%' }}
                        src={coverUrl?.replace('.jpg', '-640w.jpg')}
                        alt={title}
                      />
                    </picture>
                    <div css={articleDetails}>
                      <h4 css={articleAuthor}>{postedBy}</h4>
                      <h5 css={articleTitle}>{title}</h5>
                      <p css={articleContent}>{description}</p>
                    </div>
                  </a>
                </div>
              ),
            )}
          </div>
        </div>
      </section>
    )
  )
}

export default BlogSection
