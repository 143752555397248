/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState, useRef } from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import NavBar from './NavBar.js'
import NavBarButton from './NavBarButton.js'
import NavBarFiller from './NavBarFiller.js'
import NavBarLink from './NavBarLink.js'

const link = css`
  color: white;
  display: inline-block;
  font-size: 16px;
  line-height: 48px;
  height: 48px;
  padding: 0px 16px;
  position: relative;
  :hover {
    color: white;
  }
  @media (max-width: 1199px) {
    padding: 0 8px;
  }
`

function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    [ref.current], // eslint-disable-line
  )

  return [ref, value]
}

const NavBarDropdownFeatures = ({ features, locale }) => {
  const { t } = useTranslation(locale)
  const [hoverRef, isHovered] = useHover()
  return (
    features && (
      <div ref={hoverRef} css={{ display: 'flex', flexDirection: 'column' }}>
        <Link css={link} to={`/${locale}/`}>
          {t('Features')} <span css={{ fontSize: 12 }}>▼</span>
        </Link>
        <div
          css={{
            position: 'absolute',
            background: 'white',
            border: '1px solid #f2f2f2',
            borderRadius: 4,
            display: isHovered ? 'flex' : 'none',
            flexDirection: 'column',
            top: 65,
          }}
        >
          {Object.keys(features).map(feature => {
            let featurePath = features[feature][locale] || features[feature].en
            return (
              <Link
                key={feature}
                css={{
                  lineHeight: '48px',
                  padding: '0 16px',
                  textTransform: 'capitalize',
                }}
                to={`/${locale}/${featurePath}/`}
              >
                {t(
                  `__navigation_features_${featurePath?.replaceAll(
                    '-',
                    '_',
                  )}__`,
                  featurePath?.replaceAll('-', ' '),
                )}
              </Link>
            )
          })}
        </div>
      </div>
    )
  )
}

const NavigationDesktopComponent = ({
  locale,
  AppIcon,
  appUrl,
  appName,
  features,
  showDashboard,
}) => {
  const { t } = useTranslation(locale)

  return (
    <NavBar>
      <a aria-label={appName} href={`/${locale}/`}>
        {AppIcon}
      </a>
      <NavBarFiller />
      <NavBarDropdownFeatures features={features} locale={locale} />
      <NavBarLink label={t('Blog')} href={`/blog/${locale}/`} />
      <NavBarLink label={t('Docs')} href={`/docs/${locale}/`} />
      <NavBarLink label={t('Pricing')} href={`/${locale}/pricing/`} />
      <NavBarLink label={t('Login')} href={`${appUrl}/login`} />
      {showDashboard && (
        <NavBarButton label={t('Go to the App')} href={appUrl} />
      )}
      {!showDashboard && (
        <NavBarButton label={t('Sign up')} href={`${appUrl}/signup`} />
      )}
    </NavBar>
  )
}

export default NavigationDesktopComponent
