/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'

const link = css({
  color: 'white',
  fontSize: 16,
  position: 'relative',
  height: 45,
  cursor: 'pointer',
  padding: 14,
  borderBottom: 'solid',
  borderBottomWidth: 1,
  borderBottomColor: '#DCDCDC',
  margin: '0px 20px',
  textDecoration: 'none',
  paddingLeft: 0,
  '&:not(.disabled):active': {
    color: 'var(--color-secondary-dark)',
    borderBottomColor: 'var(--color-secondary-dark)',
  },
  WebkitTapHighlightColor: 'transparent',
})

const NavBarSmartphoneLink = ({ label, to, href }) => {
  if (href) {
    return (
      <a css={link} href={href}>
        {label}
      </a>
    )
  } else {
    return (
      <Link css={link} to={to}>
        {label}
      </Link>
    )
  }
}

export default NavBarSmartphoneLink
